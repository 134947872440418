<template>
  <div class="form-elements">
    <div class="row">
      <div class="col-md-12">
        <vuestic-widget :loading="loading">
          <template slot="header">
            <div class="w-100 d-flex justify-content-between align-items-center">
              <div class="title">
                Agency Accounts
                <span class="inst_av">( Archived )</span>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <div>
                  <b-nav pills>
                    <b-nav-item><router-link :to="{name: 'agencyaccounts'}">Active</router-link></b-nav-item>
                    <b-nav-item active><router-link :to="{name: 'admin.agencies.archived'}">Archived</router-link></b-nav-item>
                  </b-nav>
                </div>
                <div class="searchForm pl-2">
                  <text-input name="search" v-model="search" placeholder="Search..." style="width: 250px"></text-input>
                  <button class="btn btn-default btn-primary btn-sm ml-2" @click="handleSearch()" :disabled="!search">
                    <i class="fa fa-search"></i> Filter
                  </button>
                  <button class="btn btn-default btn-primary btn-sm ml-2" @click="clearSearch()">
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </template>
          
          <datatable v-bind="$data" class="le-datatable">
          </datatable>
        </vuestic-widget>
      </div>
    </div>
    <vuestic-modal
      :isOpen="isOpenModalRestore"
      @ok="handleRestoreAgency"
      @cancel="closeModalRestore"
      okText="Restore"
      cancelText="Cancel"
    >
      <span slot="title">Restore Agency</span>

      <div v-if="selectedAgency">
        Confirm you want to restore 
        <span v-if="selection.length">
          selected agencies
        </span>
        <span v-else>
          agency for {{ selectedAgency.name }}
        </span>
      </div>
    </vuestic-modal>

    <vuestic-modal
      :isOpen="isOpenModalDelete"
      @ok="handleDeleteAgency"
      @cancel="closeModalDelete"
      okText="Delete"
      cancelText="Cancel"
      okClass="btn btn-danger"
    >
      <span slot="title" class="text-danger font-weight-bold">Delete Agency</span>

      <div v-if="selectedAgency">
        Confirm you want to delete 
        <span v-if="selection.length">
          selected agencies
        </span>
        <span v-else>
          agency for <b>{{ selectedAgency.name }}</b>
        </span>
      </div>
      <div>The agency will be permanently deleted.</div>
    </vuestic-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import components from '../common/tables/comps'
export default {
  name: 'AgencyAccounts', // `name` is required as a recursive component
  props: ['row'], // from the parent FriendsTable (if exists)
  created() {
  },

  mounted()  {
    this.xprops.eventbus.$on('restoreAgency', (agency) => {
      this.isOpenModalRestore = true
      this.selectedAgency = agency
    })

    this.xprops.eventbus.$on('deleteAgency', (agency) => {
      this.isOpenModalDelete = true
      this.selectedAgency = agency
    })
  },

  computed: {
  },

  data () {
    return {
      supportBackup: false,
      supportNested: true,
      HeaderSettings: false,
      tblClass: 'table-bordered',
      tblStyle: 'color: #666',
      pageSizeOptions: [10, 25, 50, 100],
      columns: (() => {
        const cols = [
          { title: 'ID', field: 'id', label: 'Agency ID', sortable: true, visible: true },
          { title: 'Agency Name', sortable: true, field: 'name', thComp: 'FilterTh', tdStyle: { fontStyle: 'normal' } },
          { title: 'Total Installs', field: 'number_of_installs', sortable: true, visible: true, tdClass: 'center' },
          { title: 'Operation', tdComp: 'AgencyOpt', visible: 'true', tdClass: 'center' },
        ]
        return cols
      })(),
      data: [],
      total: 0,
      selection: [],
      summary: {},
      query: {
        search: null,
        status: 'archived',
      },
      search: null,
      xprops: {
        eventbus: new Vue(),
      },
      loading: false,
      selectedAgency: null,
      isOpenModalRestore: false,
      isOpenModalDelete: false,
    }
  },

  watch: {
    query: {
      handler () {
        this.handleQueryChange()
      },
      deep: true
    }
  },
  methods: {
    handleSearch () { 
      this.query.search = this.search
    },

    clearSearch () {
      this.search = null
      this.query.search = null
      this.handleQueryChange()
    },

    handleDeleteAgency () {
      this.loading = true
      const agencyIdes = this.selection.length ? this.selection.map(item => item.id) : [this.selectedAgency.id]
      
      this.$store.dispatch('agency/deleteAgencies', agencyIdes)
        .then(res => {
          this.closeModalDelete()
          this.handleQueryChange()
        })
        .catch(errors => {
          this.loading = false
        })
    },

    handleRestoreAgency () {
      if (this.selection.length) {
        this.bulkRestoreAgencies();
      } else {
        this.restoreAgency()
      }
    },
    
    restoreAgency () {
      this.loading = true
      this.$store.dispatch('agency/restoreAgency', this.selectedAgency.id)
        .then(res => {
          this.closeModalRestore()
          this.handleQueryChange()
        })
        .catch(errors => {
          this.loading = false
        })
    },

    bulkRestoreAgencies () {
      let agencyIds = this.selection.map(item => item.id)

      this.loading = true
      this.$store.dispatch('agency/bulkRestoreAgencies', agencyIds)
        .then(res => {
          this.closeModalRestore()
          this.selection = []
          this.handleQueryChange()
        })
        .catch(errors => {
          this.loading = false
        })
    },

    handleQueryChange () {
      this.loading = true
      this.$store.dispatch("agency/getAgencies", this.query).then(res => {
        this.loading = false
          this.data = res.data
          this.total = res.meta.total
      })
      .catch(error => {
        this.loading = false
      })
    },

    alertSelectedUids () {
      alert(this.selection.map(({ uid }) => uid))
    },

    closeModalDelete () {
      this.isOpenModalDelete = false
      this.selectedAgency = null
    },

    closeModalRestore () {
      this.isOpenModalRestore = false
      this.selectedAgency = null
    },
  }
}
</script>
<style>
.w-240 {
  width: 240px;
}
</style>
